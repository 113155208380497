@font-face {
    font-family: 'Rubik';
    src: url('../src/Fonts/Rubik-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Roboto-Light';
    src: url('../src/Fonts/Roboto-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  * {
    box-sizing: border-box;
    
  }
 
  p {
    font-size: 14px;
  }
  
  .container {
    background-color: #FFFFFF;
    padding: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  
  
  .form-header {
    text-align: center;
    margin-bottom: 20px;
    font-family: 'Roboto-Light', 'Helvetica', sans-serif;
    font-size: 80%;
  }
  
  .test-form {
    background-color: #CCCCCC;
    border-radius: 10px;
    padding: 20px;
    color: #444444;
    font-family: 'Rubik', 'Helvetica', sans-serif;
    text-align: center;
    margin: auto;
    max-width: 600px;
  }
  
  .test-form input, textarea, select {
    background-color: #ECECEC;
    border: none;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    color: #444444;
    width: 100%;
  }
  
  .test-form .question-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }
  
  .test-form label {
    display: block;
    margin-bottom: 10px;
    font-size: 14px;
  }
  
  .test-form h2 {
    font-family: 'Rubik', 'Helvetica', sans-serif;
    font-weight: bold;
    font-size: 24px;
    color: #444444;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .test-form h4 {
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: justify;
  }
  
  .test-form p, ol, li {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 14px;
    text-align: justify;
  }
  
  
  .test-form textarea[name="suggestions"] {
    height: 100px;
    resize: vertical;
  }
  
  .test-form button {
    background-color: #172342;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-right: 10px;
    font-family: 'Roboto-Light', 'Helvetica', sans-serif;
  }
  
  .test-form button:hover {
    background-color:#fc5000;  
  }
  
  .test-form button:disabled {
    background-color: #808080;
    cursor: not-allowed;
  }
  
  .test-form input[type="radio"]:checked + label:before {
    background-color: #172342;
  }
  
  .test-form select[type="radio"]:checked + label:before {
    background-color: #172342;
  }
  .logo {
    width: 15rem;
    height: auto;
    margin: auto;
  }
  
  .progress-bar {
    width: 100%;
    background-color: #F2F2F2;
    border-radius: 5px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  
  .progress-bar-fill {
    height: 10px;
    background-color: #fc5000;
    border-radius: 5px;
    transition: width 0.3s;
  }
  
  .error {
    color: #172342;
    font-weight: bold;
  }

  /* Media queries for responsiveness */
  @media (max-width: 600px) {
    .container {
      padding: 10px;
    }
      
    .test-form {
      padding: 10px;
      max-width: 100%;
    }
      
    .logo {
      width: 20vw;
    }
      
    h2 {
      font-size: 4vw;  /* adjust as needed */
    }
      
    .test-form h4, .test-form p, p, .test-form ol, .test-form li ,.test-form label {
      font-size: 4vw;  /* adjust as needed */
    }
  
    .test-form button {
      padding: 1vw;
      font-size: 4vw;
    }
  }